.Home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fedfcc;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1000;
}
@media (max-width: 475px) {
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1000;
  }
}
